export const showEventDetails = e => {
  const event = e.currentTarget;
  if (document.body.classList.contains("closing-event")) {
    return false;
  }

  const first = event.getBoundingClientRect();

  const translateY = first.top;
  const scaleX = first.width / window.innerWidth;
  const scaleY = first.height / 150;

  // Invert.
  details.style.transform = `scaleX(${scaleX}) scaleY(${scaleY}) translateX(33px) translateY(${translateY}px)`;
  details.style.transformOrigin = `center ${translateY}px`;

  const info = JSON.parse(event.dataset.event);

  //set event details
  details.querySelector("#details-name").innerHTML = info.title;
  const from_minute = info.from_minute.toLocaleString(undefined, {
    minimumIntegerDigits: 2
  });
  const to_minute = info.to_minute.toLocaleString(undefined, {
    minimumIntegerDigits: 2
  });
  details.querySelector("#details-hour").innerHTML = `Today<br>${
    info.from_hour
  }:${from_minute} - ${info.to_hour}:${to_minute}`;
  details.querySelector("#details-category").innerHTML = `Events<br><span>
    joubran.jad@gmail.com
  </span>`;

  window.history.pushState({ page: "details" }, info.title, "details");

  setTimeout(() => {
    requestAnimationFrame(() => {
      document.body.classList.add("show-details");
    });
  }, 40);
};
