const highlightEvent = event => {
  resetHighlights();
  event.currentTarget.classList.add("highlighted");
};

const resetHighlights = () => {
  const highlighted = $(".calendar .highlighted");
  if (highlighted) {
    highlighted.classList.remove("highlighted");
  }
};

$$(".hour").forEach(hour => {
  hour.addEventListener("click", highlightEvent);
});
