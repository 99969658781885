$("#details-close").addEventListener("click", () => {
  window.history.replaceState({ page: "calendar" }, "Calendar", "/");

  const body = document.body;
  body.classList.add("closing-details");
  body.classList.remove("show-details");
  body.addEventListener("transitionend", event => {
    if (event.srcElement.getAttribute("id") === "details") {
      body.classList.remove("closing-details");
    }
  });
});
