//for testing
const date = new Date();
let today = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();

export const events = {
  [today]: [
    {
      from_hour: 8,
      from_minute: 20,
      to_hour: 9,
      to_minute: 20,
      title: "Book flight"
    },
    {
      from_hour: 14,
      from_minute: 0,
      to_hour: 15,
      to_minute: 0,
      title: "Prepare dinner"
    },
    {
      from_hour: 20,
      from_minute: 0,
      to_hour: 21,
      to_minute: 0,
      title: "Pack"
    }
  ]
};