import {events} from './testing-events';
import {showEventDetails} from './show-details';

//move this to worker thread
export const showTodaysEvents = () => {
  const date = new Date();
  const today =
    date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();

  const todaysEvents = events[today];
  if (!todaysEvents) {
    return false;
  }
  const container = $("#events");
  for (const event of todaysEvents) {
    const from = event.from_hour;
    const to = event.to_hour;

    const position = from + event.from_minute / 60;

    const height = to - from + (event.to_minute - event.from_minute) / 60;

    const entry = document.createElement("div");
    entry.classList.add("event");
    entry.style.height = `calc(${height} * var(--calendar-hour))`;
    entry.style.transform = `translateY(calc(${position} * var(--calendar-hour))`;

    entry.innerHTML = event.title + '<div class="rippleJS"></div>';
    entry.onclick = showEventDetails;
    entry.dataset.event = JSON.stringify(event);
    container.appendChild(entry);
  }
};