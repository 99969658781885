import {getShortDay, getLongMonth} from './helpers/date';

export const setDefaultState = () => {
  window.history.pushState({ page: "calendar" }, "Calendar", "/");
};

export const setupDates = () => {
  const date = new Date();

  const month = getLongMonth(date);
  const shortDay = getShortDay(date);

  $("#month").innerHTML = month;
  $("#today-short").innerHTML = shortDay;
  $("#today-number").innerHTML = date.getDate();
};