import "./helpers/dom";

import { setDefaultState, setupDates } from "./setup";
import { showTodaysEvents } from "./fill-events";
import { showNowBar } from "./now-bar";
import "./details";
import "./highlight";
import "./navigation";
import "./calendars";
// import "./zoom";
import "./../node_modules/vanilla-ripplejs/ripple.min";

setDefaultState();

setupDates();

showTodaysEvents();

showNowBar();
