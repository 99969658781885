export const showNowBar = () => {
  const date = new Date();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const position = hour + minute / 60;

  const nowBar = $("#now-bar");
  nowBar.style.setProperty('--bar-position', position);
};
