const locale = "en-US";

export const getLongMonth = date => {
  return new Intl.DateTimeFormat(locale, { month: "long" }).format(date);
};
export const getShortMonth = date => {
  return new Intl.DateTimeFormat(locale, { month: "short" }).format(date);
};

export const getShortDay = date => {
  return new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);
};

export const getLongDay = date => {
  return new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);
};